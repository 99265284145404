import React from "react"
import {Link, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from '../layouts/layout'
import { graphql } from 'gatsby'
import PostPreviewHome from '../components/PostPreviewHome'
import Style from './index.module.scss'


const Home = (props) => {
    const {t} = useTranslation();
    const posts = [];
    props.data.allMarkdownRemark.edges.forEach(edge => posts.push(

        <li className={Style.list_item} key={edge.node.frontmatter.title}>
            <Link className={Style.link} to={'/blog/' + edge.node.frontmatter.slug}>
                <PostPreviewHome title={edge.node.frontmatter.title} date={edge.node.frontmatter.date} />
            </Link>
        </li>
    ))

    return (
        <div>
            <Layout>
                <div className={Style.content_header}>
                        <h1 className={Style.title_h1}>
                            {t("home_title")}
                        </h1>
                        <h3 className={Style.title_h4}>
                            <span className={Style.bordeaux}>BORDEAUX</span>
                            <br />
                            <span className={Style.france}>FRANCE</span>
                        </h3>
                </div>

                <div className={Style.mobile}>
                    <p className={Style.subtitle}>{t("home_last")}</p>
            
                    <ul className={Style.list}>
                        {posts}
                    </ul>
                    <h2 className={Style.title_h2}>
                        {t("home_h2")}
                    </h2>
                </div>
            </Layout>
        </div>
    )
}

export default Home


export const query = graphql`
    query ($language: String) {
        allMarkdownRemark(limit: 2, sort: {order: DESC, fields: frontmatter___date}, filter: {frontmatter: {language: {eq: $language}, type: {eq: "blog"}}}) {
            edges {
                node {
                frontmatter {
                    language
                    title
                    date(formatString: "LL", locale: $language)
                    slug
                }
                timeToRead
                html
                }
            }
        },
        locales: allLocale(filter: {language: {eq: $language}}) {
            edges {
              node {
                ns
                data
                language
              }
            }
          }
    }
    `