import React from "react"

import Style from './postpreviewhome.module.scss'

const PostPreviewHome = (props) => {

    return (
        <article className={Style.post_preview}>
            <header>
                <h3 className={Style.title}>{props.title}</h3>
                <small className={Style.post_info}>{props.date}</small>
            </header>
        </article>
    )
}

export default PostPreviewHome
